import { defineSlotRecipe } from '@chakra-ui/react';
import { toastAnatomy } from '@chakra-ui/react/anatomy';

export const toastSlotRecipe = defineSlotRecipe({
  slots: toastAnatomy.keys(),
  base: {
    root: {
      '&[data-type=error]': {
        bg: '{colors.red.100}',
        color: '{colors.gray.900}',
      },
      '&[data-type=success]': {
        bg: '{colors.green.100}',
        color: '{colors.gray.900}',
      },
      '&[data-type=warning]': {
        color: '{colors.gray.900}',
      },
    },
    title: {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    description: {
      fontSize: 'sm',
      fontWeight: 'normal',
    },
    closeTrigger: {
      color: '{colors.gray.900}',
    },
  },
});
