import { Input, JsxStyleProps } from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from 'components/icons';
import { InputGroup } from 'components/ui/input-group';
import debounce from 'lodash/debounce';
import { forwardRef, useRef } from 'react';

type SearchInputProps = {
  placeholder?: string;
  query: string;
  setQuery: (query: string) => void;
  styles?: JsxStyleProps;
};

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ placeholder = 'Search', query, setQuery, styles }, forwardedRef) => {
    const localRef = useRef<HTMLInputElement | null>(null);
    const ref = (forwardedRef || localRef) as React.RefObject<HTMLInputElement>;

    const handleQueryChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery((event.target as HTMLInputElement).value);
    }, 500);

    const handleEnter = debounce((event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const inputValue = (event.target as HTMLInputElement).value;
        setQuery(inputValue);
      }
    }, 100);

    const handleQueryReset = () => {
      if (ref.current) {
        ref.current.value = '';
      }
      setQuery('');
    };
    const { width: inputWidth = '100%' } = styles || {};

    return (
      <InputGroup
        {...styles}
        width={{ base: inputWidth as number | string, md: inputWidth as number | string, sm: '220px' }}
        startElement={<SearchIcon />}
        endElement={!!query && <CloseIcon style={{ cursor: 'pointer' }} onClick={handleQueryReset} />}
      >
        <Input
          ref={ref}
          name="global-search-input"
          placeholder={placeholder}
          defaultValue={query}
          onKeyDown={handleEnter}
          onChange={handleQueryChange}
          required={false}
        />
      </InputGroup>
    );
  }
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
