import { Box, Flex } from '@chakra-ui/react';
import { ProgressCircleRing } from 'components/ui/progress-circle';

const AppLoader = ({ pastDelay = true }: { pastDelay?: boolean }): React.ReactNode => {
  if (pastDelay) {
    return (
      <Box
        css={{
          height: '50vh',
          width: '50vw',
          position: 'fixed',
          margin: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Flex direction="column" gap={10} alignItems={'center'}>
          <ProgressCircleRing color="primary.600" />
        </Flex>
      </Box>
    );
  }
  return null;
};

export default AppLoader;
