'use client';

import type { CollectionItem } from '@chakra-ui/react';
import { Box, Flex, Icon, Portal, Select as ChakraSelect } from '@chakra-ui/react';
import * as React from 'react';
import { MdInfoOutline } from 'react-icons/md';

import { CloseButton } from './close-button';
import { Tooltip } from './tooltip';

interface SelectTriggerProps extends ChakraSelect.ControlProps {
  clearable?: boolean;
}

export const SelectTrigger = React.forwardRef<HTMLButtonElement, SelectTriggerProps>(
  function SelectTrigger(props, ref) {
    const { children, clearable, ...rest } = props;
    return (
      <ChakraSelect.Control {...rest}>
        <ChakraSelect.Trigger ref={ref}>{children}</ChakraSelect.Trigger>
        <ChakraSelect.IndicatorGroup>
          {clearable && <SelectClearTrigger />}
          <ChakraSelect.Indicator />
        </ChakraSelect.IndicatorGroup>
      </ChakraSelect.Control>
    );
  }
);

const SelectClearTrigger = React.forwardRef<HTMLButtonElement, ChakraSelect.ClearTriggerProps>(
  function SelectClearTrigger(props, ref) {
    return (
      <ChakraSelect.ClearTrigger asChild {...props} ref={ref}>
        <CloseButton size="xs" variant="plain" focusVisibleRing="inside" focusRingWidth="2px" pointerEvents="auto" />
      </ChakraSelect.ClearTrigger>
    );
  }
);

interface SelectContentProps extends ChakraSelect.ContentProps {
  portalled?: boolean;
  portalRef?: React.RefObject<HTMLElement>;
}

export const SelectContent = React.forwardRef<HTMLDivElement, SelectContentProps>(function SelectContent(props, ref) {
  const { portalled = true, portalRef, ...rest } = props;
  return (
    <Portal disabled={!portalled} container={portalRef}>
      <ChakraSelect.Positioner>
        <ChakraSelect.Content minWidth="max-content" {...rest} ref={ref} />
      </ChakraSelect.Positioner>
    </Portal>
  );
});

export const SelectItem = React.forwardRef<HTMLDivElement, ChakraSelect.ItemProps>(function SelectItem(props, ref) {
  const { item, children, ...rest } = props;
  return (
    <ChakraSelect.Item justifyContent="flex-start" key={item.value} item={item} {...rest} ref={ref}>
      <ChakraSelect.Context>
        {({ getItemState }) => {
          const { selected } = getItemState(props);
          return (
            <>
              <ChakraSelect.ItemIndicator />
              {!selected ? <Box w="4" h="4" /> : undefined}
              {children}
            </>
          );
        }}
      </ChakraSelect.Context>
    </ChakraSelect.Item>
  );
});

export const SubCategorySelectItem = ({
  item,
  children,
  tooltip,
  ...rest
}: ChakraSelect.ItemProps & { tooltip: React.ReactNode }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <Tooltip
      openDelay={0}
      closeDelay={0}
      open={open}
      key={item.name}
      content={tooltip}
      positioning={{ placement: 'right-start' }}
    >
      <SelectItem className="group" alignItems="center" justifyContent="flex-start" item={item} {...rest}>
        {children}

        <Flex justifyContent="center" alignItems="center" w="4" h="4" ml="auto">
          <Icon
            display="none"
            _groupHover={{ display: 'inline-block' }}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            color="tPurple.base"
          >
            <MdInfoOutline />
          </Icon>
        </Flex>
      </SelectItem>
    </Tooltip>
  );
};

interface SelectValueTextProps extends Omit<ChakraSelect.ValueTextProps, 'children'> {
  children?(items: CollectionItem[]): React.ReactNode;
}

export const SelectValueText = React.forwardRef<HTMLSpanElement, SelectValueTextProps>(
  function SelectValueText(props, ref) {
    const { children, ...rest } = props;
    return (
      <ChakraSelect.ValueText {...rest} ref={ref}>
        <ChakraSelect.Context>
          {select => {
            const items = select.selectedItems;
            if (items.length === 0) return props.placeholder;
            if (children) return children(items);
            if (items.length === 1) return select.collection.stringifyItem(items[0]);
            return `${items.length} selected`;
          }}
        </ChakraSelect.Context>
      </ChakraSelect.ValueText>
    );
  }
);

export const SelectRoot = React.forwardRef<HTMLDivElement, ChakraSelect.RootProps>(function SelectRoot(props, ref) {
  return (
    <ChakraSelect.Root {...props} ref={ref} positioning={{ sameWidth: true, ...props.positioning }}>
      {props.asChild ? (
        props.children
      ) : (
        <>
          <ChakraSelect.HiddenSelect />
          {props.children}
        </>
      )}
    </ChakraSelect.Root>
  );
}) as ChakraSelect.RootComponent;

interface SelectItemGroupProps extends ChakraSelect.ItemGroupProps {
  label: React.ReactNode;
}

export const SelectItemGroup = React.forwardRef<HTMLDivElement, SelectItemGroupProps>(
  function SelectItemGroup(props, ref) {
    const { children, label, ...rest } = props;
    return (
      <ChakraSelect.ItemGroup {...rest} ref={ref}>
        <ChakraSelect.ItemGroupLabel>{label}</ChakraSelect.ItemGroupLabel>
        {children}
      </ChakraSelect.ItemGroup>
    );
  }
);

export const SelectLabel = ChakraSelect.Label;
export const SelectItemText = ChakraSelect.ItemText;
