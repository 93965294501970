import { IconButton } from '@chakra-ui/react';
import { FilingIcon } from 'components/icons';
import { ProgressCircleRing, ProgressCircleRoot } from 'components/ui/progress-circle';
import useQueryParam from 'hooks/useQueryParam';
import { TransactionInstance } from 'types/shared-types';

type TransactionDetailsToolbarProps = {
  data?: TransactionInstance;
  isPending: boolean;
  isError: boolean;
};

const TransactionDetailsToolbar = ({ data: transactionData, isPending, isError }: TransactionDetailsToolbarProps) => {
  const [, setSearchParams] = useQueryParam('showFilingDetails');

  if (!transactionData || isError) {
    return null;
  }

  return (
    transactionData?.filing_id && (
      <IconButton
        rounded={'sm'}
        variant={'ghost'}
        aria-label="transaction-filing"
        onClick={e => {
          e.stopPropagation();
          setSearchParams({ showFilingDetails: 'true', filingId: transactionData.filing_id });
        }}
        color={'#000'}
      >
        {isPending && (
          <ProgressCircleRoot>
            <ProgressCircleRing />
          </ProgressCircleRoot>
        )}
        <FilingIcon />
      </IconButton>
    )
  );
};

export default TransactionDetailsToolbar;
