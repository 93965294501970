import { defineRecipe } from '@chakra-ui/react';

export const badgeRecipe = defineRecipe({
  base: {
    borderRadius: 'l1',
    justifyContent: 'center',
  },
  defaultVariants: {
    size: 'md',
  } as any, // TODO: we need to wait for chakra-ui to fix typings here
});
