import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { handleApiErrorMessage } from 'apis/api';
import { BILLING_STATE_KEY, getBillingCheckoutHostedPage, getOrgBillingDetails } from 'apis/billing-apis';
import { toaster } from 'components/ui/toaster';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { BillingPlanEnum } from 'schema/types-schema.d';
import { chargebee } from 'utils/chargebee';

export const usePaywall = () => useContext(PayWallContext);

export const usePayWallProvider = () => {
  const { orgId, isTest } = useOrg();
  const { track } = useTracking();
  const [billingPlan, setBillingPlan] = useState<BillingPlanEnum>(BillingPlanEnum.FREE);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('in_trial');
  const [isPaidUser, setIsPaidUser] = useState<boolean>(false);
  const [isSubscriptionPausedOrCanceled, setIsSubscriptionPausedOrCanceled] = useState<boolean>(false);

  useEffect(() => {
    setIsPaidUser(!!(billingPlan && billingPlan !== BillingPlanEnum.FREE));
  }, [billingPlan]);

  useEffect(() => {
    setIsSubscriptionPausedOrCanceled(['CANCELLED', 'PAUSED'].includes(subscriptionStatus));
  }, [subscriptionStatus]);

  useQuery({
    queryKey: [BILLING_STATE_KEY, 'plan', orgId],
    queryFn: async () => {
      const res = await getOrgBillingDetails(orgId);
      if (res) {
        setBillingPlan(res.billing_plan);
        setSubscriptionStatus(res.subscription_status);
      }
      return res;
    },
    enabled: !!orgId,
  });

  const openPaymentCheckout = async ({ onSuccess, onClose, onError }: PaymentCheckoutParams) => {
    if (isPaidUser) {
      onSuccess && onSuccess();
      return;
    }
    const chargebeeInstance = await chargebee(isTest);
    chargebeeInstance?.openCheckout({
      hostedPage: async () => {
        return await getBillingCheckoutHostedPage(orgId, BillingPlanEnum.GROWTH);
      },
      close: () => {
        onClose && onClose();
        chargebeeInstance?.closeAll();
      },
      step: (currentStep: string) => {
        track('chargebee checkout', { step: currentStep });
      },
      success: () => {
        onSuccess && onSuccess();
        setBillingPlan(BillingPlanEnum.GROWTH);
      },
      error: (error: Error) => {
        const errorMessage = handleApiErrorMessage(error);
        toaster.create({
          type: 'error',
          title: errorMessage?.title,
          description: errorMessage?.message,
        });
        onError && onError();
        captureException(error);
      },
      layout: 'in_app',
    });
    return chargebeeInstance;
  };

  return { billingPlan, isPaidUser, openPaymentCheckout, isSubscriptionPausedOrCanceled };
};

type PaywallProviderType = {
  billingPlan: BillingPlanEnum;
  isPaidUser: boolean;
  isSubscriptionPausedOrCanceled: boolean;
  openPaymentCheckout: (params: PaymentCheckoutParams) => void;
};

type PaymentCheckoutParams = {
  onSuccess?: () => void;
  onError?: () => void;
  onClose?: () => void;
};

const defaultContext = {
  billingPlan: BillingPlanEnum.FREE,
  isPaidUser: false,
  isSubscriptionPausedOrCanceled: false,
} as PaywallProviderType;

const PayWallContext = createContext<PaywallProviderType>(defaultContext);

export const PayWallProvider = ({ children }: PropsWithChildren) => {
  const data = usePayWallProvider();
  return <PayWallContext.Provider value={data}>{children}</PayWallContext.Provider>;
};
