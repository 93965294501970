import { Circle, Float, Icon, IconButton, PopoverRootProps } from '@chakra-ui/react';
import { PopoverBody, PopoverContent, PopoverRoot, PopoverTrigger } from 'components/ui/popover';
import { PropsWithChildren } from 'react';
import { MdFilterList } from 'react-icons/md';

export const FilterPopover = ({
  children,
  hasFilter = false,
  ...rest
}: PropsWithChildren<PopoverRootProps & { hasFilter?: boolean }>) => {
  return (
    <PopoverRoot {...rest}>
      <PopoverTrigger>
        <IconButton variant="plain">
          <Icon w="6" h="6">
            <MdFilterList />
          </Icon>
          {hasFilter && (
            <Float placement="top-end" offsetX="2.5" offsetY="3">
              <Circle bg="secondary.500" size="8px" outline="0.2em solid" outlineColor="bg" />
            </Float>
          )}
        </IconButton>
      </PopoverTrigger>
      <PopoverContent colorPalette="blue">
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  );
};

export const TableFilterPopover = ({
  children,
  hasFilter = false,
  ...rest
}: PropsWithChildren<PopoverRootProps & { hasFilter?: boolean }>) => {
  return (
    <PopoverRoot {...rest}>
      <PopoverTrigger asChild>
        <IconButton variant="plain">
          <Icon w="6" h="6">
            <MdFilterList />
          </Icon>
          {hasFilter && (
            <Float placement="top-end" offsetX="2.5" offsetY="3">
              <Circle bg="secondary.500" size="8px" outline="0.2em solid" outlineColor="bg" />
            </Float>
          )}
        </IconButton>
      </PopoverTrigger>
      <PopoverContent colorPalette="blue">
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  );
};
