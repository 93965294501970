import 'react-datepicker/dist/react-datepicker.css';
import './app-fonts';

import { AuthProvider, RedirectToLogin, RequiredAuthProvider } from '@propelauth/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'components/ui/provider';
import { Toaster } from 'components/ui/toaster';
import useIsEmbeddedInIframe from 'hooks/useIsEmbeddedInIframe';
import { WithTrpcWithPropelAuth } from 'hooks/useTrpcWithPropelAuth';
import { StrictMode } from 'react';

import { AppContent } from './app-content';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: failureCount => failureCount < 2,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
      refetchOnWindowFocus: false,
      networkMode: 'online',
      refetchOnMount: false,
    },
    mutations: {
      networkMode: 'online',
      onError: (error: any) => {
        // TODO: handle global error message
        console.log('error ==>', error);
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      // TODO: handle global error message
      console.log('error ==>', error);
    },
  }),
});

const App = () => {
  const isEmbeddedInIframe = useIsEmbeddedInIframe();

  // If the app is embedded in an iframe, this means the app is opened in Shopify
  // Then, we will open it in a new tab for authentication if needed
  // ToDo: we should open new tab only when the user is not authenticated
  const AuthProviderWrapper = isEmbeddedInIframe ? AuthProvider : RequiredAuthProvider;
  return (
    <StrictMode>
      <Provider>
        <QueryClientProvider client={queryClient}>
          <AuthProviderWrapper
            authUrl={import.meta.env.VITE_AUTH_URL}
            displayIfLoggedOut={<RedirectToLogin postLoginRedirectUrl={window.location.href} />}
          >
            <Toaster />
            <WithTrpcWithPropelAuth />
            <AppContent isEmbeddedInIframe={isEmbeddedInIframe} />
          </AuthProviderWrapper>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </StrictMode>
  );
};

export default App;
