import { defineSlotRecipe } from '@chakra-ui/react';
import { dialogAnatomy } from '@chakra-ui/react/anatomy';

export const dialogSlotRecipe = defineSlotRecipe({
  slots: dialogAnatomy.keys(),
  base: {
    content: {
      borderRadius: 'l2',
    },
    header: {
      px: 4,
    },
    body: {
      px: 4,
    },
    footer: {
      px: 4,
    },
    title: {
      fontWeight: 'medium',
    },
  },
});
