import { Icon, Text, useDisclosure } from '@chakra-ui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { createApiDeckConnection, updateApiDeckConnections } from 'apis/connections';
import { getOrgs } from 'apis/organizations-apis';
import { useAppLoaderWrapper } from 'app/app-loader-wrapper';
import SearchInput from 'components/SearchInput';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { useHandleNotification } from 'hooks/useApiNotification';
import { Organization, setOrg, useOrg } from 'hooks/useOrg';
import { clearOrgDataFromWindow } from 'hooks/useTrpcWithPropelAuth';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { MdOutlineSyncAlt } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { markKintsugiAppAsInstalled } from 'utils/rippling-oauth';

import { OrganizationSelectorModalBody } from './org-selector-modal-body';

type OrganizationSelectorV2Props = {
  isExpanded: boolean;
};

export const OrganizationSelector = ({ isExpanded }: OrganizationSelectorV2Props) => {
  const { orgId, name } = useOrg();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { open, onOpen, onClose } = useDisclosure();
  const { showLoader, hideLoader } = useAppLoaderWrapper();

  const [{ page, size }, setPagination] = useState<{ page: number; size: number }>({
    page: 1,
    size: 10,
  });

  const [query, setQuery] = useState('');
  const { handleFailNotification } = useHandleNotification();

  useHotkeys('ctrl+o', () => onOpen(), [open]);

  const switchOrg = searchParams.get('switchOrg');
  const archiveOrgId = searchParams.get('archiveOrg');
  const serviceId = searchParams.get('service_id');
  const code = searchParams.get('code');
  const redirectUri = searchParams.get('redirect_uri');

  //for shopify
  const switchOrgForShopifyURL = searchParams.get('shop');
  const shopId = switchOrgForShopifyURL?.split('.')[0] || '';
  const { data, isPending, error } = useQuery({
    queryKey: ['orgs', page, size, query],
    queryFn: async () => {
      const { data } = await getOrgs(orgId, page, size, query);
      return data;
    },
    select: data => {
      return {
        items: data.items.filter((org: any) => org.external_id !== archiveOrgId),
        pages: data.pages,
        total: data.total,
      };
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
  const organizations = data?.items || [];
  const pages = data?.pages || 1;

  const renderButton = orgId ? (
    <Text lineClamp={1} color="#fff" title={name}>
      {name}
    </Text>
  ) : (
    'Select Organization'
  );

  const handleRedirection = async (orgId: string) => {
    try {
      if (serviceId === 'rippling' && code && redirectUri) {
        await handleHrisRedirection(orgId);
      } else if (switchOrgForShopifyURL && shopId) {
        navigateToShopify();
      } else {
        await handleFallbackNavigation();
      }
    } catch (error) {
      console.error('Error during redirection:', error);
      handleFailNotification({
        message: `An error occurred during redirection.`,
      });
    }
  };

  const handleHrisRedirection = async (orgId: string) => {
    if (!code || !serviceId || !redirectUri) {
      return;
    }
    showLoader();
    const apideck = await createApiDeckConnection(orgId, serviceId, {});
    if (apideck.data) {
      await markKintsugiAppAsInstalled(code, orgId, apideck.data.connection_id);
      await updateApiDeckConnections(apideck.data.connection_id, orgId, 'ACTIVE');
      window.location.href = decodeURIComponent(redirectUri);
    } else {
      handleFailNotification({
        message: `Failed to connect to the ${serviceId} service.`,
      });
    }
    hideLoader();
  };

  const navigateToShopify = () => {
    const path = `/connect/shopify?shopURL=${switchOrgForShopifyURL}`;
    navigate(path);
  };

  const handleFallbackNavigation = async () => {
    await new Promise(r => setTimeout(r, 50));
    navigate('/', { replace: true });
  };

  const handleOrgSwitch = async (selectedOrg: Organization) => {
    if (orgId === selectedOrg.orgId) {
      handleClose();
      await handleHrisRedirection(orgId);
      return;
    }

    // Clear token from window so react query does not make an
    // api request with previous org access token
    clearOrgDataFromWindow();
    setOrg(selectedOrg);
    handleClose();
    await handleRedirection(selectedOrg.orgId);
  };

  const handlePagination = async (page: number, size: number) => {
    setPagination({ page, size });
  };

  const handleClose = () => {
    setQuery('');
    setSearchParams({});
    setPagination({ page: 1, size: 10 });
    onClose();
    if (switchOrgForShopifyURL && shopId) {
      const path = `/connect/shopify?shopURL=${switchOrgForShopifyURL}`;
      navigate(path);
    }
  };

  useEffect(() => {
    if (!open && (switchOrg || (switchOrgForShopifyURL && shopId) || serviceId)) {
      onOpen();
    }
  }, [switchOrg, switchOrgForShopifyURL, shopId, serviceId]);

  useEffect(() => {
    if (
      (!isPending && (error?.name === 'CanceledError' || (error as any)?.response?.status === 401)) ||
      (!isPending && !open && !orgId && organizations.length > 1)
    ) {
      onOpen();
    }
  }, [error, isPending, onOpen]);

  return (
    <DialogRoot
      onOpenChange={({ open }) => {
        if (!open && searchParams.has('switchOrg')) {
          setSearchParams(params => {
            params.delete('switchOrg');
            return params;
          });
        }
      }}
      scrollBehavior="inside"
      onEscapeKeyDown={onClose}
      open={open}
    >
      <DialogBackdrop />
      <DialogTrigger asChild>
        <Button
          role="button"
          variant="nav"
          cursor="pointer"
          onClick={() => {
            onOpen();
          }}
          borderWidth={'1px'}
          borderColor={'#333854'}
          px={'8px'}
          justifyContent="space-between"
          style={isExpanded ? {} : { width: 'unset' }}
          loading={isPending}
        >
          {isExpanded ? renderButton : null}
          <Icon ml={'5px'} size="sm">
            <MdOutlineSyncAlt />
          </Icon>
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogCloseTrigger onClick={onClose} />
        <DialogHeader>
          <DialogTitle>Select Organization to Connect</DialogTitle>
        </DialogHeader>
        <DialogBody>
          {(data?.total >= 10 || !!query) && <SearchInput query={query} setQuery={setQuery} />}

          <OrganizationSelectorModalBody
            isLoading={isPending}
            orgId={orgId}
            query={query}
            organizations={organizations}
            onOrgSwitch={handleOrgSwitch}
            pagination={{ pages, page, size, onPaginate: handlePagination }}
          />
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};
