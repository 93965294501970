import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { useLocationData } from 'hooks/useLocationData';
import { useStateOptions } from 'hooks/useStateOptions';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { ChangeEvent, useEffect, useState } from 'react';

type FilingFilterProps = {
  tableFilters: UseTableFiltersType;
  onClose: () => void;
};

const FilingFilter = ({ onClose, tableFilters }: FilingFilterProps) => {
  const { countries } = useLocationData();
  const { stateOptions, fetchStateOptions } = useStateOptions();
  const { start_date, end_date, state_code, country_code, setFilters, resetFilters } = tableFilters;
  const [accordionIndex, setAccordionIndex] = useState<string[]>([]);

  const [startDate, setStartDate] = useState<string | undefined>(start_date);
  const [endDate, setEndDate] = useState<string | undefined>(end_date);
  const [selectedState, setSelectedState] = useState<string | undefined>(state_code);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(country_code);

  useEffect(() => {
    if (selectedCountry) {
      fetchStateOptions(selectedCountry);
    }
  }, [selectedCountry, fetchStateOptions]);

  const onCountryChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(e.target.value);
    setSelectedState(undefined);
  };

  const handleSave = () => {
    setFilters({
      state_code: selectedState || undefined,
      start_date: startDate || undefined,
      end_date: endDate || undefined,
      country_code: selectedCountry || undefined,
    });

    onClose();
  };

  const handleClear = () => {
    resetFilters();
    onClose();
  };

  const hasFilter = selectedState || startDate || endDate || selectedCountry;

  return (
    <Stack>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight="medium">
          Filters
        </Text>
        {hasFilter && (
          <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
            Clear All Filters
          </Text>
        )}
      </HStack>
      <AccordionRoot
        variant="plain"
        multiple
        value={accordionIndex}
        onValueChange={({ value }) => setAccordionIndex(value)}
      >
        <AccordionItem value="country">
          <AccordionItemTrigger>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Country
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent mb={2}>
            <NativeSelectRoot>
              <NativeSelectField value={selectedCountry} onChange={onCountryChange} placeholder="Select">
                {countries.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
          </AccordionItemContent>
        </AccordionItem>
        <AccordionItem value="jurisdiction">
          <AccordionItemTrigger>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Jurisdiction
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent mb={2}>
            <NativeSelectRoot>
              <NativeSelectField
                value={selectedState}
                onChange={e => setSelectedState(e.target.value)}
                placeholder="Select"
              >
                {stateOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
          </AccordionItemContent>
        </AccordionItem>
        <AccordionItem value="date">
          <AccordionItemTrigger>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Date
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent mb={4}>
            <Text fontWeight="medium" mb={2}>
              Start Date
            </Text>
            <DatePicker selected={startDate} onChange={date => setStartDate(date)} placeholderText="Select" />
            <Text fontWeight="medium" mt={4} mb={2}>
              End Date
            </Text>
            <DatePicker selected={endDate} onChange={date => setEndDate(date)} placeholderText="Select" />
          </AccordionItemContent>
        </AccordionItem>
      </AccordionRoot>
      <HStack direction="row" justifyContent={'space-around'} gap="18px">
        <Button variant="outline" colorPalette="gray" onClick={onClose} width={'132px'} size="xs">
          Cancel
        </Button>
        <Button variant="solid" color="primary" onClick={handleSave} width={'132px'} size="xs" disabled={!hasFilter}>
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};

export default FilingFilter;
