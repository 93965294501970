import { HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getPhysicalNexus, PHYSICAL_NEXUS_KEY } from 'apis/physical-nexus-apis';
import { getTransactions, TRANSACTION_STATE_KEY } from 'apis/transactions';
import AppHeader from 'app/app-header';
import { PhysicalNexusToolbar } from 'app-header-toolbar/physical-nexus-toolbar';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';
import { useState } from 'react';
import { PhysicalNexusInstance } from 'types/shared-types';

import { PhysicalNexusModal } from './components/physical-nexus-modal';
import { PhysicalNexusTable } from './components/physical-nexus-table';

const Presence = () => {
  const { onOpen, open, onClose } = useDisclosure();
  const { orgId } = useOrg();
  const { page, size, setFilters } = useTableFilters();
  const [physicalNexus, setPhysicalNexus] = useState<PhysicalNexusInstance>();

  const {
    isPending: isPhysicalNexusDataPending,
    data,
    isError,
  } = useQuery({
    queryKey: [PHYSICAL_NEXUS_KEY, orgId, page, size],
    queryFn: async () => {
      const { data } = await getPhysicalNexus(orgId, { page, size });
      return data;
    },
  });

  const { isPending: isTransactionAvailablePending, data: isTransactionAvailable = false } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, orgId, page, size],
    queryFn: async () => {
      const { data } = await getTransactions(orgId, { page, size });
      return data;
    },
    select: ({ items }) => items.length > 0,
  });

  const physicalNexusItems = data?.items || [];
  const isPaginationEnable = physicalNexusItems.length && (data?.total ?? 0) > DEFAULT_PAGE_SIZE;
  const totalPages = data?.pages ?? 0;

  if (isError && !data) {
    return null;
  }
  const isAllPending = isPhysicalNexusDataPending || isTransactionAvailablePending;

  const handleOnEdit = (physicalNexus: PhysicalNexusInstance) => {
    setPhysicalNexus(physicalNexus);
    onOpen();
  };

  return (
    <>
      <Stack height={'100%'}>
        <HStack gap={1} justifyContent={'space-between'}>
          <AppHeader />
          <PhysicalNexusToolbar />
        </HStack>
        <PhysicalNexusTable
          data={physicalNexusItems}
          isTransactionAvailable={isTransactionAvailable}
          onEdit={handleOnEdit}
          isPending={isAllPending}
        />
        {isPaginationEnable && (
          <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
        )}
      </Stack>
      {open && <PhysicalNexusModal isOpen={open} onClose={onClose} physicalNexus={physicalNexus} />}
    </>
  );
};

export default Presence;
