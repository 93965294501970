import { CheckboxGroup, HStack, Icon, IconButton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { ColumnIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import {
  PopoverBody,
  PopoverCloseTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverRoot,
  PopoverTrigger,
} from 'components/ui/popover';
import { Tooltip } from 'components/ui/tooltip';
import { useState } from 'react';
import { capitalizeFirstLetter } from 'utils';

type ColumnFilterProps = {
  headers: string[];
  onToggleColumn: (columns: string[]) => void;
  visibleColumns: string[];
  dropdownTopOffset?: number;
};

/**
 * ColumnFilter component allows users to toggle the visibility of table columns.
 * It accepts the headers of all table as props and displays checkbox with those headers in dropdown.
 * visibleColumns contain header names that are to be displayed in table
 * The dropdown is positioned relative to the filter button, position can be changed by dropdownTopOffset.
 */
const ColumnFilter = ({ headers, onToggleColumn, visibleColumns }: ColumnFilterProps) => {
  const { open, onClose, onOpen, onToggle } = useDisclosure();
  const [selectedColumns, setSelectedColumns] = useState<string[]>(visibleColumns);

  const handleCheckboxChange = (column: string) => {
    setSelectedColumns(prev => (prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]));
  };

  const handleApply = () => {
    onToggleColumn(selectedColumns);
  };

  const handleCancel = () => {
    setSelectedColumns(visibleColumns);
    onClose();
  };

  return (
    <PopoverRoot
      closeOnInteractOutside
      open={open}
      onPointerDownOutside={onToggle}
      positioning={{ placement: 'bottom-start' }}
    >
      <PopoverTrigger>
        <IconButton
          variant="secondary-outline"
          style={{
            float: 'right',
          }}
          size="md"
          aria-label="Filter columns"
          onClick={onOpen}
        >
          <Tooltip content="Customize Columns" positioning={{ placement: 'left' }}>
            <Icon w="6" h="6">
              <ColumnIcon />
            </Icon>
          </Tooltip>
        </IconButton>
      </PopoverTrigger>
      <PopoverContent colorPalette="blue">
        <PopoverCloseTrigger onClick={onClose} />
        <PopoverHeader>Customize Columns</PopoverHeader>
        <PopoverBody>
          <Stack gap="4" mb={'4'}>
            <CheckboxGroup>
              {headers.map(column => (
                <Checkbox
                  key={column}
                  checked={selectedColumns.includes(column)}
                  onChange={() => handleCheckboxChange(column)}
                  gap="8px"
                >
                  <Text>{capitalizeFirstLetter(column)}</Text>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </Stack>
          <HStack direction="row" justifyContent={'space-around'} gap="18px">
            <Button variant="outline" color="secondary" width={'132px'} onClick={handleCancel}>
              Cancel
            </Button>

            <Button variant="solid" color="primary" width={'132px'} onClick={handleApply}>
              Apply
            </Button>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default ColumnFilter;
