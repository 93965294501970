import { Flex, Icon, Spinner, Table, TableRootProps, Text } from '@chakra-ui/react';
import { Checkbox } from 'components/ui/checkbox';
import TableContainer from 'components/ui/table-container';
import { Children, cloneElement, isValidElement, ReactNode, useState } from 'react';
import { CheckboxRowProps } from 'types/data-table';

import ColumnFilter from './ColumnFilter';

type KDataTableProps = {
  onboardingTable?: string;
  headers: string[];
  numericCols?: string[];
  centerColumns?: string[];
  sortHeaders?: string[];
  handleSort?: (header: string) => string;
  getSortIcon?: (header: string) => ReactNode;
  children: ReactNode;
  tableStyle?: TableRootProps;
  defaultVisibleColumns?: string[];
  showColumnFilter?: boolean;
  getHeaderRightIcon?: (header: string) => ReactNode;
  onVisibleColumnsChange?: (columns: string[]) => void;
  height?: string;
  columnWidths?: Record<string, string>;
  isPending?: boolean;

  // Checkbox props
  enableCheckboxSelection?: boolean;
  checkedRows?: string[];
  onRowCheck?: (selectedIds: string[]) => void;
  getRowId?: (row: any) => string;
  isRowCheckable?: (row: any) => boolean;
};

export const KDataTable = ({
  onboardingTable = '',
  headers,
  numericCols = [],
  tableStyle,
  children,
  defaultVisibleColumns = headers,
  showColumnFilter = false,
  onVisibleColumnsChange,
  centerColumns,
  sortHeaders,
  handleSort,
  getSortIcon,
  getHeaderRightIcon,
  height,
  columnWidths,
  isPending,

  // Checkbox props
  enableCheckboxSelection = false,
  checkedRows = [],
  onRowCheck,
  getRowId,
  isRowCheckable = () => true,
}: KDataTableProps) => {
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultVisibleColumns);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
    if (onVisibleColumnsChange) {
      onVisibleColumnsChange(columns);
    }
  };

  const visibleHeaders = headers.filter(header => visibleColumns.includes(header));
  const checkboxSelectableRows = enableCheckboxSelection
    ? Children.toArray(children)
        ?.filter(isValidElement)
        ?.filter((child: React.ReactElement) => isRowCheckable(child.props.data))
    : [];

  const totalCheckboxSelectableRows = checkboxSelectableRows?.length;
  const allSelected = enableCheckboxSelection
    ? checkedRows?.length === totalCheckboxSelectableRows && totalCheckboxSelectableRows > 0
    : false;

  const handleCheckboxSelectAll = () => {
    if (!onRowCheck || !getRowId) return;

    if (allSelected || (checkedRows.length > 0 && checkedRows.length < totalCheckboxSelectableRows)) {
      onRowCheck([]);
    } else {
      const allIds = checkboxSelectableRows.map(child => getRowId((child as React.ReactElement).props.data));
      onRowCheck(allIds);
    }
  };

  return (
    <TableContainer data-onboarding={onboardingTable} height={height} position="relative">
      <Table.ScrollArea>
        <Table.Root size="md" variant="line" {...tableStyle}>
          <Table.Header>
            <Table.Row>
              {enableCheckboxSelection && (
                <Table.ColumnHeader width="40px" padding="0 0 0 16px">
                  <Checkbox
                    checked={checkedRows.length > 0 && !allSelected ? 'indeterminate' : allSelected}
                    onCheckedChange={() => handleCheckboxSelectAll()}
                  />
                </Table.ColumnHeader>
              )}
              {visibleHeaders.map((header: string, idx: number) => {
                const isSortableCol = sortHeaders?.includes(header);
                let textAlign = 'start';
                if (numericCols.includes(header)) {
                  textAlign = 'end';
                }
                if (centerColumns?.includes(header)) {
                  textAlign = 'center';
                }
                return (
                  <Table.ColumnHeader
                    key={`${idx}-${header}`}
                    textAlign={textAlign}
                    onClick={() => isSortableCol && handleSort?.(header)}
                    width={{ base: columnWidths?.[header], md: 'auto' }}
                    style={{ minWidth: columnWidths?.[header] }}
                  >
                    <Flex
                      justifyContent={numericCols.includes(header) ? 'flex-end' : undefined}
                      alignItems={'center'}
                      cursor={isSortableCol ? 'pointer' : 'default'}
                    >
                      <Text fontSize="xs" fontWeight="bold">
                        {header.toUpperCase()}
                      </Text>
                      {getHeaderRightIcon?.(header)}
                      {isSortableCol && getSortIcon && <Icon fontSize={'18px'}>{getSortIcon(header)}</Icon>}
                    </Flex>
                  </Table.ColumnHeader>
                );
              })}
              {showColumnFilter && (
                <Table.ColumnHeader py="0" textAlign={'right'}>
                  <ColumnFilter headers={headers} onToggleColumn={handleToggleColumn} visibleColumns={visibleColumns} />
                </Table.ColumnHeader>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {' '}
            {enableCheckboxSelection
              ? Children.map(children, child => {
                  if (isValidElement(child)) {
                    return cloneElement(child, {
                      enableSelection: true,
                      isSelected: checkedRows.includes(getRowId?.(child.props.data) ?? ''),
                      onRowCheck,
                      getRowId,
                      checkedRows,
                    } as CheckboxRowProps);
                  }
                  return child;
                })
              : children}
          </Table.Body>
        </Table.Root>
      </Table.ScrollArea>
      {isPending && (
        <Flex pos="absolute" top="0" right="0" w="full" h="full" bg="white" opacity={0.8} justify="center" zIndex="100">
          <Spinner marginTop={100} size="lg" />
        </Flex>
      )}
    </TableContainer>
  );
};
