import { Flex, Icon, Input, Link, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CONNECTIONS_STATE_KEY, createStripe } from 'apis/connections';
import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { InfoIconFilled } from 'components/icons';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { SmartInput } from 'components/ui/samrt-input';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { StripeIcon } from './icons/stripe';

// const STRIPE_APP_URL = import.meta.env.VITE_STRIPE_MARKETPLACE_URL;

const stripeValidationSchema = object().shape({
  publishable_key: string(),
  api_key: string().test(
    'match',
    `Please ensure you're using a matching secret key and publishable key`,
    function (value) {
      if (!value) {
        return false;
      }
      return (
        (value.includes('test') && this.parent?.publishable_key?.includes('test')) ||
        (value.includes('live') && this.parent?.publishable_key?.includes('live'))
      );
    }
  ),
});

const StripeConnectModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { orgId, isTest } = useOrg();
  const { handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const [, setSearchParams] = useQueryParam('liveSource');
  const navigate = useNavigate();

  const stripeFormik = useFormik({
    initialValues: {
      publishable_key: '',
      api_key: '',
    },
    validationSchema: stripeValidationSchema,
    onSubmit: values => {
      stripeMutation.mutate(values);
    },
  });

  const stripeMutation = useMutation({
    mutationFn: (payload: { publishable_key: string; api_key: string }) => {
      return createStripe(orgId, payload.publishable_key, { api_key: payload.api_key });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      onClose();
      handleSuccessNotification('Stripe connected Successfully.');
      navigate('/source');
    },
  });

  const handlePatternMatched = () => {
    stripeFormik.resetForm();
    onClose();
    setSearchParams({ liveSource: 'stripe' });
  };

  return (
    <DialogRoot scrollBehavior="inside" closeOnInteractOutside={false} size={'md'} open={isOpen}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Connect Stripe</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Field
            label="Stripe Publishable Key"
            invalid={!!stripeFormik.errors.publishable_key}
            errorText={stripeFormik.errors.publishable_key}
          >
            <Input
              id="publishable_key"
              name="publishable_key"
              value={stripeFormik.values.publishable_key}
              onChange={stripeFormik.handleChange}
            />
          </Field>
          <Field
            label={
              <Flex direction={'row'} gap={2} align="center" mt={2}>
                Stripe Secret Key
                <Tooltip
                  closeDelay={5000}
                  closeOnPointerDown
                  content={
                    <Flex direction="column" w="100%" alignItems="center">
                      <Text color="fffff" fontWeight={'normal'} fontSize={'sm'} lineHeight={'20px'} text-align="center">
                        You can find the instructions to get the keys
                      </Text>
                      <span>
                        <Link
                          textDecoration={'underline'}
                          target="_blank"
                          href="https://kintsugi.readme.io/docs/connect-stripe-to-kintsugi-organization"
                          rel="noreferrer"
                        >
                          here
                        </Link>
                      </span>
                    </Flex>
                  }
                >
                  <Icon mt={2} size="md" alignItems="center">
                    <InfoIconFilled />
                  </Icon>
                </Tooltip>
              </Flex>
            }
            invalid={!!stripeFormik.errors.api_key}
            errorText={stripeFormik.errors.api_key}
          >
            <SmartInput
              id="api_key"
              name="api_key"
              value={stripeFormik.values.api_key}
              onChange={stripeFormik.handleChange}
              shouldMatchPattern={isTest}
              pattern={'^sk_live_\\w*$'}
              onPatternMatched={handlePatternMatched}
            />
          </Field>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={stripeMutation.isPending}
              variant="solid"
              color="primary"
              width={'90px'}
              onClick={() => stripeFormik.handleSubmit()}
              disabled={!(stripeFormik.isValid && stripeFormik.dirty)}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default function StripeTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();
  const { open, onOpen, onClose } = useDisclosure();
  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);

  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;
  // const openInNewTab = () => {
  //   window.open(STRIPE_APP_URL, '_blank', 'noopener,noreferrer');
  // };

  const handleOpenStripeModal = () => {
    setIsBusinessAddressPromptOpen(false);
    onOpen();
  };

  // const handleOnOpen = () => {
  // if (isTest) {
  // handleOpenStripeModal();
  // } else {
  //   openInNewTab();
  // }
  // };

  // const handleConnect = () => {
  //   setIsBusinessAddressPromptOpen(false);

  //   // if (isTest) {
  //   handleOpenStripeModal();
  //   // } else {
  //   //   openInNewTab();
  //   // }
  // };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title={'Stripe'}
        description={'Financial Infrastructure for the Internet'}
        icon={<StripeIcon />}
        onConnect={() => (isAddressMissing ? setIsBusinessAddressPromptOpen(true) : onOpen())}
        btnProps={{ colorPalette: 'gray' }}
        installationGuide="https://trykintsugi.com/blog/how-to-integrate-kintsugi-sales-tax-automation-with-stripe-a-step-by-step-guide"
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={handleOpenStripeModal}
      />
      <StripeConnectModal isOpen={open} onClose={onClose} />
    </>
  );
}
