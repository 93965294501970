import { Badge } from '@chakra-ui/react';
import { ExemptionStatus } from 'types/shared-types';

type ExemptionBadgeProps = {
  readonly status: ExemptionStatus;
};

export default function ExemptionBadge({ status }: ExemptionBadgeProps) {
  if (status === ExemptionStatus.ACTIVE) {
    return <Badge colorPalette={'green'}>Active</Badge>;
  }
  if (status === ExemptionStatus.INACTIVE) {
    return <Badge colorPalette={'grey'}>Inactive</Badge>;
  }
  if (status === ExemptionStatus.EXPIRED) {
    return <Badge colorPalette={'red'}>Expired</Badge>;
  }
  if (status === ExemptionStatus.DEACTIVATED) {
    return <Badge colorPalette={'grey'}>Deactivated</Badge>;
  }
  if (status === ExemptionStatus.ARCHIVED) {
    return <Badge colorPalette={'grey'}>Archived</Badge>;
  }
  return null;
}
